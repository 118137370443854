import axios from "axios";
import all_general_storage from "./storage";
import env from "react-dotenv";

const api = env.API_BASE_URL;

const config = {
    headers: {
        'Content-Type': 'application/json'
    },
}

//Axios Post
const post_api = (route, data) => {
    config.headers['x-access-token'] = all_general_storage.getToken();
    return axios.post(api + route, data, config)
}


//Axios Get
const get_api = (route) => {
    config.headers['x-access-token'] = all_general_storage.getToken()
    return axios.get(api + route, config)
}


//Axios Put
const put_api = (route, data) => {
    config.headers['x-access-token'] = all_general_storage.getToken();
    return axios.put(api + route, data, config)
}



//Axios Delete
const delete_api = (route, data) => {
    var config = {
        method: 'delete',
        url: api + route,
        headers: {
            'x-access-token': all_general_storage.getToken(),
            'Content-Type': 'application/json'
        },
        data: data
    };

    config.headers['x-access-token'] = all_general_storage.getToken();
    return axios(config)
}




const BaseService = {
    post_api,
    get_api,
    put_api,
    delete_api
}

export default BaseService;