import React, { useEffect, useState } from 'react'
import env from 'react-dotenv'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer
} from '@react-google-maps/api'
import { iconPath, mapPins } from './iconPath'

const MapLayOut = props => {
  const [map, setMap] = useState(null)
  const [dataSuccess, setDataSuccess] = useState(false)
  const [socketData, setSocketData] = useState([])
  const [setMarker, setMarkerIcon] = useState(false)

  const pickUpLatitude = props.tripData.pickUpLatitude
  const pickUpLongitude = props.tripData.pickUpLongitude

  const destinationLatitude = props.tripData.destinationLatitude
  const destinationLongitude = props.tripData.destinationLongitude

  const { io } = require('socket.io-client')

  const containerStyle = {
    width: '100%',
    height: '100vh'
  }

  const center = {
    lat: -3.745,
    lng: -38.523
  }

  //socketStuff
  const initChannel = 'lol'
  const tripData = {
    tripId: props.tripId
  }

  const socket = io(`https://therydsomapp.herokuapp.com/rydsome-realtime`, {
    transports: ['websocket']
  })

  const startSocket = () => {
    if (props.status) {
    } else {
      socket.on('connect', () => {
        console.log('socket started')
        socket.emit('/rydsom/connect-trip', tripData)

        //   socket.on('/rydsom/connect-trip', res => {
        //     console.log(res)
        //   })

        socket.on(`${props.driver_id}/location`, response => {
        //   console.log(response)
          setSocketData(response)
          setDataSuccess(true)

          //   const SetStas = props.setStats
          //   if (response.currentStatus === 'Trip Completed') {
          //     SetStas(response.currentStatus)
          //   }
        })

        socket.on(`${props.tripId}/events`, response => {
          console.log(response)
          const SetStas = props.setStats
          if (response.payload.current_event.name === "Trip Ended") {
            SetStas(response.currentStatus)

            props.getData();
            //disconnect socket
            socket.disconnect();
            
          }
        })
      })
    }
  }

  useEffect(() => {
    startSocket();
  }, [props.loadingData, props.status])

  //   map things
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.MAP_KEY
  })

  const onLoad = React.useCallback(async function callback (map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: pickUpLatitude,
      lng: pickUpLongitude
    })
    map.fitBounds(bounds)
    setMap(map)
    setMarkerIcon(true)
  }, [])

  const onUnmount = React.useCallback(function callback (map) {
    setMap(null)
    setMarkerIcon(true)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={{
        lat: pickUpLatitude,
        lng: pickUpLongitude
      }}
      options={{
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoom: 13
      }}
    >
      {setMarker ? (
        <>
          {/* Pick up location */}
          <Marker
            position={{ lat: pickUpLatitude, lng: pickUpLongitude }}
            label='Pickup'
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
            }}
          />
          {/* Drop location */}
          <Marker
            position={{ lat: destinationLatitude, lng: destinationLongitude }}
            label='Destination'
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
            }}
          />
        </>
      ) : (
        <></>
      )}

      {dataSuccess ? (
        <>
          <Marker
            position={{ lat: socketData.latitude, lng: socketData.longitude }}
            label=''
            icon={{
              path: iconPath,
              fillColor: '#ff6700',
              fillOpacity: 0.9,
              scale: 0.1,
              strokeColor: 'black',
              strokeWeight: 1,
              rotation: socketData.rotation
            }}
          />
        </>
      ) : (
        <></>
      )}
    </GoogleMap>
  ) : (
    <>Map not loaded</>
  )
}

export default React.memo(MapLayOut)
// export default MapLayOut
