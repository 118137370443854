import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { BiCurrentLocation, BiErrorCircle, BiMap } from 'react-icons/bi'
import BaseService from '../mixins/baseServices'
import global_variables from '../mixins/globarVars'
import moment from 'moment-timezone'
import env from 'react-dotenv'
import MapLayOut from '../components/Maps/map_layout'
import ShowAlert from '../components/Alerts/all_alerts';

const api = env.API_BASE_URL

const mapsKey = env.MAP_KEY

const RideDetails = () => {
  const [loading, setLoading] = useState(true)
  const [theData, setTheData] = useState([])
  const [theTripId, setTheTripId] = useState('')
  const [tripStatus, setTripStatus] = useState('')

  const urlParams = new URLSearchParams(window.location.search)
  const id = urlParams.get('ryd')

  useEffect(() => {
    //onload
    getData();
  }, [])

  const getData = () => {
    setTheTripId(id)

    if (id) {
      // The URL has an "id" query parameter
      //   console.log('found')

      const the_route = global_variables().retrieve_trip

      const data = {
        tripId: id
      }

      BaseService.put_api(the_route, data)
        .then(response => {
          //   console.log(response.data.payload)
          setLoading(false)
          setTheData(response.data.payload)
        })
        .catch(error => {
          console.log(error);
          ShowAlert.error_alert('Oops!', error.response.data.error, 'Retry')
            .then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            })
        })
    } else {
      // The URL does not have an "id" query parameter
      console.log('not found');
      setTheData([]);
      ShowAlert.info_alert('Sorry!', 'No trips available to show', 'Okay')
        .then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
    }
  }


  return (
    <>
      <div>
        {loading ? (
          <>
            <div className='flex items-center justify-center h-screen'>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <MapLayOut
            setStats={setTripStatus}
            tripId={id}
            status={theData.trip_data.completed}
            driver_id={theData.trip_data.driverId}
            tripData={theData.trip_data}
            getData={getData}
          />
        )}

        <div className='fixed bottom-8 left-0 right-0 mx-2'>
          <Card className='shadow-sm border-none rounded-xl'>
            <Card.Body>
              {loading ? (
                <></>
              ) : theData.trip_data.completed === false ? (
                <>
                  <div>
                    <p className='font-bold text-2xl'>
                      {theData.user_data.fullName} is en route
                    </p>

                    <p className='text-default_grey text-xl font-semibold uppercase mt-3'>
                      {theData.driver_data.fullName}
                    </p>

                    <div className='mt-2 flex justify-between'>
                      <div>
                        <p className='text-xl font-semibold'>
                          {theData.car_data.model +
                            ', ' +
                            theData.car_data.carColor}
                        </p>
                        <p className='text-xl font-semibold mt-2'>
                          {theData.car_data.registeration}
                        </p>
                      </div>
                      <div>
                        <img
                          className='img-fluid rounded-full'
                          src={theData.driver_data.image}
                          alt='...'
                          width='60px'
                        />
                      </div>
                    </div>

                    <div className='mt-2 mb-1'>
                      <div className='flex gap-2'>
                        <BiCurrentLocation className='mt-2.5 text-2xl text-default_orange' />
                        <p className='text-lg mt-2'>
                          {theData.trip_data.pickUpPlace}
                        </p>
                      </div>
                      <div className='mt-1 flex gap-2'>
                        <BiMap className='mt-2.5 text-2xl text-default_green' />
                        <p className='text-lg mt-2'>
                          {theData.trip_data.destinationPlace}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : tripStatus === 'Trip Completed' ||
                theData.trip_data.completed === true ? (
                <>
                  <div>
                    <p className='font-bold text-2xl'>
                      {theData.user_data.fullName} has arrived
                    </p>

                    <p className='text-default_grey text-xl font-semibold uppercase mt-3'>
                      {theData.driver_data.fullName}
                    </p>

                    <div className='mt-2 flex justify-between'>
                      <div>
                        <p className='text-xl font-semibold'>
                          {theData.car_data.model +
                            ', ' +
                            theData.car_data.carColor}
                        </p>
                        <p className='text-xl font-semibold mt-2'>
                          {theData.car_data.registeration}
                        </p>
                      </div>
                      <div>
                        <img
                          className='img-fluid rounded-full'
                          src={theData.driver_data.image}
                          alt='...'
                          width='60px'
                        />
                      </div>
                    </div>

                    <div className='mt-2 mb-1'>
                      <div className='flex gap-2'>
                        <BiCurrentLocation className='mt-2.5 text-2xl text-default_orange' />
                        <p className='text-lg mt-2'>
                          {theData.trip_data.pickUpPlace}
                        </p>
                      </div>
                      <div className='mt-1 flex gap-2'>
                        <BiMap className='mt-2.5 text-2xl text-default_green' />
                        <p className='text-lg mt-2'>
                          {theData.trip_data.destinationPlace +
                            ', ' +
                            moment(
                              theData.trip_data.currentStatus.timestamp
                            ).format('h:mma')}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : tripStatus === '' ? (
                <>
                  <div className='text-center'>
                    <BiErrorCircle
                      className='mt-2.5 text-default_orange flex mr-auto ml-auto'
                      style={{ fontSize: '5rem' }}
                    />
                    <p className='text-xl font-semibold'>No trip in progress</p>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default RideDetails
