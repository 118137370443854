const getToken = () => {
    return localStorage.getItem('token') ?? 'N/A';
}

const removeToken = () => {
    localStorage.removeItem('token');
    return true;
}


const setToken = (token) => {
    localStorage.setItem('token', token);
    return token;
}

const log_out = () => {
    localStorage.clear();
    window.location.reload();
}

const storeUserData = (data) => {
    localStorage.setItem('userData', data);
    return data;
}




const all_general_storage = {
    getToken,
    removeToken,
    setToken,
    log_out,
    storeUserData
}

export default all_general_storage;